@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i&display=swap');
@import url('/themes/thrifty/assets/fonts-thrifty/stylesheet.css');
@import '~tippy.js/dist/tippy.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-multi-carousel/lib/styles.css';
@import './app';
@import './nav';

.react-toast-notifications__container {
  z-index: 99999 !important;
}

// fix for one of the trackers not specifying their images
// to be hidden, therefore leaving a white blank space in the footer
body > img {
  display: none;
}
