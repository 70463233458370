$bezier: cubic-bezier(0.62, 0.28, 0.23, 0.99);
$blueText: #004e95;
$greyHover: #dce3e4;
$greyText: #6f6f6f;
$sunflower: #f8c513;

.mobile-navigation {
  .cross {
    cursor: pointer;
    padding: 25px;
    position: absolute;
    right: 0;
    top: 0;

    svg {
      width: 15px;
      height: 15px;
      display: block;
    }
  }
  &.active {
    display: block;
    height: 100vh;
  }
}